import {Container} from "react-bootstrap";
import React from "react";

const PageWhyHireMe = () => {
    return <>
        <h1>Why hire me?</h1>
        <Container>
            im good at stuff :3c
        </Container>
    </>;
}

export default PageWhyHireMe;
